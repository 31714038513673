import React from "react"
import SEO from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  const intl = useIntl()

  return (
    <>
      <SEO title={intl.formatMessage({ id: "home" })} />
      <div className="container" style={{ marginTop: "10%" }}>
        <div className="row align-content-center align-items-center">
          <div className="col-12 text-center">
            <div
              className="homepage-content"
              dangerouslySetInnerHTML={{
                __html:
                  data[intl.locale].mainPageContent.childContentfulRichText
                    .html,
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  {
    fa: contentfulSetting(node_locale: { eq: "fa" }) {
      mainPageContent {
        childContentfulRichText {
          html
        }
      }
    }
    en: contentfulSetting(node_locale: { eq: "en-US" }) {
      mainPageContent {
        childContentfulRichText {
          html
        }
      }
    }
  }
`

export default IndexPage
